.outer-div {
  font-size: 25px;
  text-align: center;
  background-color: #febc0c;
  height: 100vh; }

.supervisor-message {
  position: relative;
  top: 50%;
  color: #fff; }

.suppervisor-link {
  color: #85878c; }
