.loading-screen-container {
  height: 100vh;
  display: flex; }

.loading-screen-pulse-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 0; }

.loading-screen-pulse {
  margin: auto;
  height: 6rem;
  width: 6rem;
  border-radius: 9999px;
  background-color: rgba(125, 147, 255, var(--tw-bg-opacity));
  opacity: 0.75;
  --tw-bg-opacity: 1;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite; }

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0; } }

.loading-screen-logo-container {
  margin: auto;
  z-index: 30; }

.loading-screen-logo {
  width: 5rem;
  height: 5rem;
  border-radius: 9999px;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); }
